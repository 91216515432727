<template>
  <router-link :to="'/consulta/' + agenda.id ">
    <b-card no-body class="apl-grey h-100"
    
    >
      <b-card-body class="d-flex justify-content-start align-items-center">
        <!-- <div class="col-5 col-md-4">
        <img class="img-fluid" :src="require(`@/assets/images/agenda/${getIconByType()}`)" alt="icone_agenda" />
      </div> -->
        <div class="truncate ml-2">
          <h4 class="mb-25 font-weight-bolder">
            {{ agenda.patient.physicalPerson.firstName }}
            {{ agenda.patient.physicalPerson.lastName }}
          </h4>
          <div class="mt-1">
            <!-- <feather-icon size="14" icon="UsersIcon" class="mr-1" />
          <small>{{ agenda.tipo }}</small> -->

            <b-badge
              v-for="type in agenda.consultationTypes"
              :key="type.id"
              pill
              variant="primary"
              class="margem-pill"
              :class="getClassPillByType(type.name)"
              >{{ type.name }}</b-badge
            >
          </div>
          <div class="mt-1">
            <feather-icon size="14" icon="ClockIcon" class="mr-1" />
            <small>
              {{ getFromNowByDate(agenda.startDate) }}
            </small>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
import { BCard, BCardBody, BBadge } from 'bootstrap-vue'
import utilsService from '@/services/utilsService'

export default {
  components: {
    BCardBody,
    BCard,
    BBadge,
  },
  props: {
    agenda: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...utilsService,
    getColorByType(tipo) {
      switch (tipo) {
        case 'Teste Cognitivo':
          return 'secondary'
        case 'Aplicação do Jogo':
          return 'secondary'
        case 'Consulta Inicial':
          return 'secondary'
        case 'Devolutiva':
          return 'secondary'
        case 'Jogo Teste':
          return 'secondary'
        default:
          return null
      }
    },

  

    getIconByType() {
      let icon
      switch (this.agenda.tipo) {
        case 'Teste Cognitivo':
          icon = 'teste_cognitivos.svg'
          break
        case 'Aplicação do Jogo':
          icon = 'aplicacao_do_jogo.svg'
          break
        case 'Consulta Inicial':
          icon = 'consulta_inicial.svg'
          break
        case 'Devolutiva':
          icon = 'devolutiva.svg'
          break
        case 'Jogo Teste':
          icon = 'jogo_teste.svg'
          break
        default:
          break
      }
      return `icon_${icon}`
    },
    getClassPillByType(type) {
      let pillVariant
      switch (type) {
        case 'Testes Cognitivos':
          pillVariant = 'testcog'
          break
        case 'Aplicação do Jogo':
          pillVariant = 'apl-jogo'
          break
        case 'Consulta Inicial':
          pillVariant = 'cons-ini'
          break
        case 'Devolutiva':
          pillVariant = 'cons-fin'
          break
        case 'Jogo Teste':
          pillVariant = 'jogoteste'
          break
        default:
          break
      }
      return `pill-${pillVariant}`
    },

    getClassCardCorner(type) {
      let Class
      switch (type) {
       
        case 'Aplicação do Jogo':
          Class = 'c-consulta-com-apl'
          break
        case 'Consulta Inicial':
          Class = 'c-consulta-inicial'
          break
        case 'Devolutiva':
          Class= 'c-devolutiva'
          break
        
        default:
          break
      }
      return Class
    },
  },
}
</script>
