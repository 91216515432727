var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    attrs: {
      "to": '/consulta/' + _vm.appointment.id
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "size": "70px",
      "variant": "light-primary",
      "text": _vm.getAbreviateByPatient(_vm.appointment.patient)
    }
  }), _c('div', {
    staticClass: "truncate ml-2"
  }, [_c('h2', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.appointment.patient.physicalPerson.firstName) + " ")]), _c('span', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.appointment.patient.physicalPerson.lastName) + " ")]), _c('div', [_c('span', {
    staticClass: "small"
  }, [_vm._v(" " + _vm._s(_vm.getFromNowByDate(_vm.appointment.startDate)) + " ")])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }