var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    attrs: {
      "to": '/consulta/' + _vm.agenda.id
    }
  }, [_c('b-card', {
    staticClass: "apl-grey h-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('div', {
    staticClass: "truncate ml-2"
  }, [_c('h4', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.agenda.patient.physicalPerson.firstName) + " " + _vm._s(_vm.agenda.patient.physicalPerson.lastName) + " ")]), _c('div', {
    staticClass: "mt-1"
  }, _vm._l(_vm.agenda.consultationTypes, function (type) {
    return _c('b-badge', {
      key: type.id,
      staticClass: "margem-pill",
      class: _vm.getClassPillByType(type.name),
      attrs: {
        "pill": "",
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(type.name))]);
  }), 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "14",
      "icon": "ClockIcon"
    }
  }), _c('small', [_vm._v(" " + _vm._s(_vm.getFromNowByDate(_vm.agenda.startDate)) + " ")])], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }